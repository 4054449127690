import axios from 'axios';
import config from './config/domainconfig.json';

export default class Api {
  constructor() {
    this.api_token = null;
    this.client = null;
    let hostname = window.location.hostname;
    const testHostname = "claysites.herokuapp.com";// Set this to test a URL like stage or live (on localhost only)
    // const testHostname = "claysites.herokuapp.com";
    // const testHostname = "www.townmaking.com";
    // const testHostname = "www.embodiedmaking.com";
    // const testHostname = "www.product-foundry.com";
    if (hostname.indexOf("localhost") > -1 || (hostname.indexOf("192.") > -1)) {
      if (testHostname) hostname = testHostname;
    }

    // ***** Getting domain and subdomain *** //
    const parts = hostname.split(".");
    this.domain = parts[1];
    this.subdomain = parts[0];
    if (hostname.indexOf("claysites.herokuapp") > -1) {
      this.domain = process.env.REACT_APP_TEST_DOMAIN; // URL to simulate on Stage is read from env (on stage only)
      this.subdomain = process.env.REACT_APP_TEST_SUBDOMAIN;
    }
    // ***** Getting domain and subdomain *** //


    // ***** Getting env *** //
    const env = config[this.domain];
    if (!env) {
      throw new Error(("No config available for " + hostname));
    }
    if (this.subdomain && this.subdomain !== '' && env[this.subdomain]) {
      // env for subdomains
      this.env = Object.assign(env, env[this.subdomain]);
    } else {
      this.env = env;
    }
    this.api_url = this.env.baseUrl;
    this.organization = this.env.organization;
    // ***** Getting env *** //
  }

  getEnv() {
    return this.env;
  }

  getDomain() {
    return this.domain;
  }

  getSubdomain() {
    return this.subdomain;
  }

  init = (authToken) => {
    //this.api_token = getCookie("ACCESS_TOKEN");

    let headers = {
      Accept: "application/json",
      tenant: "clay"
    };

    if (authToken) {
      headers["authentication-token"] = authToken;
    }

    if (this.api_token) {
      headers.Authorization = `Bearer ${this.api_token}`;
    }

    this.client = axios.create({
      withCredentials: true,
      baseURL: this.api_url,
      timeout: 31000,
      headers: headers,
    });

    return this.client;
  };

  getExhibitConfig = () => {
    let url = window.location.origin;
    if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
      url = "http://localhost:5000";
    }
    return axios.create({
      baseURL: url,
    }).get("/config.json");
  }

  getExhibits = (authToken) => {
    return this.init(authToken).get("/content/" + this.env.organization + "/menu?sd=" + this.subdomain).then (res => {
      res.data.menus = res.data.menus.map(m => {
        m.orgId = this.env.organization;
        return m
      });
      return res;
    });
  };

  getSisterExhibits = (authToken, org) => {
    return this.init(authToken).get("/content/" + org + "/menu?sd=" + this.subdomain).then (res => {
      res.data.menus = res.data.menus.map(m => {
        m.orgId = org;
        return m
      });
      return res;
    });
  }

  getToc = (exhibitionId, authToken) => {
    return this.init(authToken).get("/content/" + exhibitionId + "/toc?sd=" + this.subdomain);
  };

  getExhibit = (exhibitionId, authToken) => {
    return this.init(authToken).get("/content/" + exhibitionId + "/root?sd=" + this.subdomain);
  }

  touch = (obj) => {
    return this.init().post("/organizations/" + this.organization + "/touches", obj);
  }

  signin = (obj) => {
    return this.init().post("/authentications", obj);
  }

  getMe = (authToken) => {
    return this.init(authToken).get("/users/me?sd=" + this.subdomain);
  }

  getCurrent = (authToken) => {
    return this.init(authToken).get("/authentications/current?sd=" + this.subdomain);
  }

  deleteCurrent = (authToken) => {
    return this.init(authToken).delete("/authentications/current?sd=" + this.subdomain);
  }

  resetPassword = (obj) => {
    return this.init().post("/users/password_reset_request?sd=" + this.subdomain, obj)
  }
}
